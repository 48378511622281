import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const PrivateRoute = ({ children }) => {

    const { isLoggedIn } = useSelector(state => state.authSlice)
    const navigate = useNavigate()

    useEffect(() => {

        if (!isLoggedIn) {
            navigate('/auth/login', { replace: true })
        }

    }, [isLoggedIn])

    return isLoggedIn && children

}

export default PrivateRoute