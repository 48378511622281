import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { queryParams } from '../../../utils/helper'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/guideline/`

export const guidelineApiService = createApi({
    reducerPath: 'guidelineApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().authSlice.token
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        createGuideline: builder.mutation({
            query: (payload) => ({
                url: 'create',
                method: 'POST',
                body: payload
            })
        }),
        getGuideline: builder.query({
            query: (args) => {

                if (args?.id) {
                    return `get/${args?.id}`
                }

                let query = queryParams(args)

                return `get${query}`

            },
            keepUnusedDataFor: 0
        }),
        postComment: builder.mutation({
            query: (payload) => ({
                url: 'comment',
                method: 'POST',
                body: payload
            })
        }),
    })
})

export const {
    useCreateGuidelineMutation,
    useGetGuidelineQuery,
    usePostCommentMutation
} = guidelineApiService