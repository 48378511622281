import { memo } from "react"

const Loader = (props) => {

    const { loading } = props

    if (loading) {
        return (
            <div className="loader-overlay">
                <div className="loader-container">
                    <div className="box"></div>
                    <div className="wavy-loader">
                        <div className="wave"></div>
                        <div className="wave"></div>
                        <div className="wave"></div>
                    </div>
                </div>
            </div>
        )
    }

    return null
}

export default memo(Loader)