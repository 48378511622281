import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { queryParams } from '../../../utils/helper'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/press/`

export const pressApiService = createApi({
    reducerPath: 'pressApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().authSlice.token
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getPress: builder.query({
            query: (args) => {

                if (args?.id) {
                    return `get/${args?.id}`
                }

                let query = queryParams(args)

                return `get${query}`

            },
            keepUnusedDataFor: 0
        })
    })
})

export const {
    useGetPressQuery
} = pressApiService